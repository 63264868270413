import { ModifyMode, RotateMode, TranslateMode } from "@deck.gl-community/editable-layers";
import { TransformIcon, MoveIcon, RotateIcon } from "components/icons";
import { EditorModeListType } from "./editor-mode-list.type";

export const editorModeList: EditorModeListType = {
  edit: [
    {
      label: "Modify",
      mode: ModifyMode,
      icon: <TransformIcon />,
    },
    {
      label: "Rotate",
      mode: RotateMode,
      icon: <RotateIcon />,
    },
    {
      label: "Move",
      mode: TranslateMode,
      icon: <MoveIcon />,
    },
  ],
  multiEdit: [
    {
      label: "Rotate",
      mode: RotateMode,
      icon: <RotateIcon />,
    },
    {
      label: "Move",
      mode: TranslateMode,
      icon: <MoveIcon />,
    },
  ],
};
