import { IconButton } from "components";
import { PublishIcon, SpinIcon } from "components/icons";
import { usePublishMap } from "./hooks/paginated-cities.hook";
import { showNotification } from "components/notification";
import { useEffect } from "react";

export const PublishMapComponent = () => {
  const { publishMap, loading, data } = usePublishMap();

  useEffect(() => {
    if (data?.publishCitiesTiles) {
      showNotification({
        variant: "success",
        message: "Map published successfully",
      });
    }
  }, [data]);

  return (
    <IconButton
      onClick={() => publishMap()}
      className="flex flex-row gap-x-1 p-2 bg-white w-28 shadow-md outline-none font-bold text-sm disabled:bg-primary/5 rounded-md text-black hover:bg-primary/5"
      disabled={loading}
    >
      {loading ? (
        <SpinIcon className="w-5 h-5 animate-spin inline-block" />
      ) : (
        <>
          Publish map
          <PublishIcon className="h-5 w-5" fill="white" />
        </>
      )}
    </IconButton>
  );
};
