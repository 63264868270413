import { AddIcon } from "assets";
import { IconButton } from "components";
import { FormInput } from "components/form-control";
import { SearchIcon } from "components/icons";
import { useRef, useState } from "react";
import { AddCity } from "./add-city/Panel";
import { PaginatedCitiesProps } from "./hooks/paginated-cities.hook";
import { PublishMapComponent } from "./PublishMap";

interface FiltersProps extends PaginatedCitiesProps {
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

export const FiltersBar = ({ setSearchValue, cityName, currentPage, setCurrentPage }: FiltersProps) => {
  const searchFieldRef = useRef<HTMLInputElement>(null);
  const [openAddCityModal, setOpenAddCityModal] = useState<boolean>(false);

  return (
    <>
      <div className="flex flex-row gap-x-2 items-center justify-between w-full">
        <div
          className="bg-white w-2/6 px-2 cursor-pointer ring-0 ring-black border rounded-md flex flex-row items-center justify-between"
          onClick={() => {
            searchFieldRef.current?.focus();
          }}
          role="none"
        >
          <div className="basis-5/6">
            <FormInput
              ref={searchFieldRef}
              className="text-black border-none w-full"
              placeholder="Recherche par nom de ville..."
              onChange={(e) => {
                const cityName = e.target.value;
                const pageIndex = 1;
                if (cityName.length >= 3 || cityName.length === 0) {
                  setSearchValue(cityName);
                  setCurrentPage({ ...currentPage, pageIndex });
                }
              }}
            />
          </div>
          <IconButton>
            <SearchIcon className="h-5 w-5  " />
          </IconButton>
        </div>
        <div className="ml-auto flex gap-x-2">
          <PublishMapComponent />
          <IconButton
            onClick={() => setOpenAddCityModal(true)}
            className="flex flex-row gap-x-1 p-2 bg-white shadow-md outline-none font-bold text-sm rounded-md text-black hover:bg-primary hover:bg-opacity-5"
          >
            Add city
            <AddIcon className="h-5 w-5" fill="white" />
          </IconButton>
        </div>
      </div>
      {openAddCityModal && (
        <AddCity
          open={openAddCityModal}
          onDismiss={setOpenAddCityModal}
          {...{ cityName, setCurrentPage, currentPage }}
        />
      )}
    </>
  );
};
