import { countrySymbol } from "constants/mapbox.const";
import { City, PagedResult } from "types";
import { ViewState } from "react-map-gl";

export type TooltipType<T> = {
  x: number;
  y: number;
  properties: T;
};

export type ViewportPlaceType = Pick<ViewState, "latitude" | "longitude"> & {
  name?: string;
};
export type MapViewType = ViewState & { width: number; height: number };

export type PlaceFilter = {
  coordinates?: ViewportPlaceType;
  keyWord: string;
};

export type interactionStateType = {
  inTransition?: boolean;
  isDragging?: boolean;
  isPanning?: boolean;
  isRotating?: boolean;
  isZooming?: boolean;
};

export type PaginatedCitiesResponse = {
  paginatedCities: PagedResult<City>;
};

export type CreateCityResponse = {
  createCity: City;
};
export type UpdateCityResponse = {
  updateCity: City;
};
export type RemoveCityResponse = {
  removeCity: City;
};
export enum MapType {
  MAPBOX = "Map box",
  ESRI_MAP = "Esri Map",
  GOOGLE_MAP = "Google Map",
  YANDEX_MAP = "Yandex Map",
}

export const mapUrlByType: {
  [mapType in MapType]: string;
} = {
  [MapType.MAPBOX]: "",
  [MapType.ESRI_MAP]: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  [MapType.GOOGLE_MAP]: `https://mt0.google.com/vt/lyrs=y&hl=${countrySymbol}&x={x}&y={y}&z={z}`,
  [MapType.YANDEX_MAP]:
    "https://sat04.maps.yandex.net/tiles?l=sat&lang=en-US&v=22.04.19-0-b220419065830&x={x}&y={y}&z={z}",
};

type CursorState = {
  /** Whether the cursor is over a pickable object */
  isHovering: boolean;
  /** Whether the cursor is down */
  isDragging: boolean;
};

export type GetCursor = (state: CursorState) => string;
