import { SVGProps } from "react";

export const RotateIcon = ({ width = 24, height = 24, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      width={width}
      height={height}
      strokeWidth="2"
      {...props}
    >
      <path d="M12 3a7 7 0 0 1 7 7v4l-3 -3"></path>
      <path d="M22 11l-3 3"></path>
      <path d="M8 15.5l-5 -3l5 -3l5 3v5.5l-5 3z"></path>
      <path d="M3 12.5v5.5l5 3"></path>
      <path d="M8 15.545l5 -3.03"></path>
    </svg>
  );
};
