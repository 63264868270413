import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { sortDesc } from "helper/array";
import {
  QUERY_CITY_LIST,
  QUERY_CREATE_CITY,
  QUERY_PAGINATED_CITIES,
  QUERY_PUBLISH_MAP,
  QUERY_REMOVE_CITY,
  QUERY_UPDATE_CITY,
} from "services/web/api-cities.const";
import {
  AllCityResponse,
  CreateCityResponse,
  FilterParams,
  PaginatedCitiesResponse,
  RemoveCityResponse,
  UpdateCityResponse,
} from "types";
import { PublishCitiesTilesResponse } from "services/web/api-cities.types";

export interface PaginatedCitiesProps {
  cityName: string;
  currentPage: FilterParams;
  setCurrentPage: React.Dispatch<React.SetStateAction<FilterParams>>;
}

export const usePaginatedCitiesActions = ({ cityName, currentPage, setCurrentPage }: PaginatedCitiesProps) => {
  const client = useApolloClient();

  const variables = {
    pageIndex: currentPage.pageIndex,
    pageSize: currentPage.pageSize,
    filter: [
      {
        field: "name",
        value: cityName,
      },
    ],
  };

  const {
    data: cities,
    loading,
    refetch,
  } = useQuery<PaginatedCitiesResponse>(QUERY_PAGINATED_CITIES, {
    variables: variables,
    onCompleted: (response) => {
      client.cache.writeQuery({
        query: QUERY_PAGINATED_CITIES,
        data: {
          paginatedCities: {
            results: response.paginatedCities.results,
            totalPages: response.paginatedCities.totalPages,
          },
        },
        variables: variables,
      });
    },
  });

  const getCachedCities = () => {
    const cachedCities = client.cache.readQuery({ query: QUERY_CITY_LIST }) as AllCityResponse;
    return cachedCities.cities;
  };

  const [deleteCity] = useMutation<RemoveCityResponse>(QUERY_REMOVE_CITY, {
    update: (cache) => {
      const cacheData = cache.readQuery({
        query: QUERY_PAGINATED_CITIES,
        variables: variables,
      }) as PaginatedCitiesResponse;
      const pageIndex =
        currentPage.pageIndex !== 1 && cacheData.paginatedCities.results.length === 1
          ? currentPage.pageIndex - 1
          : currentPage.pageIndex;
      refetch({ cityName, pageIndex: pageIndex });
      setCurrentPage({ ...currentPage, pageIndex });
    },
    onCompleted: ({ removeCity }) => {
      const cacheCities = getCachedCities();
      const newData = cacheCities.filter((item) => item.id !== removeCity.id);
      client.cache.writeQuery({
        query: QUERY_CITY_LIST,
        data: { cities: newData },
      });
    },
  });

  const [createNewCity] = useMutation<CreateCityResponse>(QUERY_CREATE_CITY, {
    update: () => {
      refetch({ cityName, pageIndex: currentPage.pageIndex });
    },
    onCompleted: ({ createCity }) => {
      const cacheCities = getCachedCities();
      const newData = sortDesc([...cacheCities, createCity], "name");
      client.cache.writeQuery({
        query: QUERY_CITY_LIST,
        data: { cities: newData },
      });
    },
  });

  const [updateCity] = useMutation<UpdateCityResponse>(QUERY_UPDATE_CITY, {
    update: () => {
      refetch({ cityName, pageIndex: currentPage.pageIndex });
    },
    onCompleted: ({ updateCity }) => {
      const cacheCities = getCachedCities();
      const newData = cacheCities.map((item) => (item.id === updateCity.id ? updateCity : item));
      client.cache.writeQuery({
        query: QUERY_CITY_LIST,
        data: { cities: newData },
      });
    },
  });

  return {
    deleteCity,
    createNewCity,
    cities,
    refetch,
    loading,
    updateCity,
  };
};

export const usePublishMap = () => {
  const [publishMap, { data, loading }] = useMutation<PublishCitiesTilesResponse>(QUERY_PUBLISH_MAP);
  return {
    publishMap,
    data,
    loading,
  };
};
