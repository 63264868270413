import { Switch, SwitchProps } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

export type McaSwitchProps = Omit<SwitchProps, "className"> & {
  className?: string;
};

export const McaSwitch = ({ className, ...props }: McaSwitchProps) => {
  return (
    <Switch
      {...props}
      className={twMerge(
        "group inline-flex h-4 w-7 mt-2 items-center rounded-full bg-gray-400 transition data-[checked]:bg-title",
        className,
      )}
    >
      <span className="size-2.5 translate-x-3.5 rounded-full bg-white transition group-data-[checked]:translate-x-1" />
    </Switch>
  );
};
